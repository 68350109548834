import intlTelInput from 'intl-tel-input';
import Cookies from 'js-cookie';
import Typebot from '@typebot.io/js/dist/web.js';

window.handleGallery = function(gravetypes, preselectedGravetypes, stoneCharasteristics, preselectedCharasteristics, stoneMaterials, autoLoad){

    const galleryCallDebounce = 850;
    const rasengrabsteine = JSON.parse(gravetypes).filter(gravetype => gravetype.name == "Urnengrabstein" || gravetype.name == "Liegegrabstein").map(gravetype => gravetype.id)

    return {
        gravestones: [],
        filteredGravestones: [],
        rasengrabsteinSelected: false,
        gravetypes: JSON.parse(gravetypes),
        filteredGravetypes: JSON.parse(preselectedGravetypes).map(element => element.id),
        stoneCharasteristics: JSON.parse(stoneCharasteristics),
        filteredStoneCharasteristics: JSON.parse(preselectedCharasteristics).map(element => element.id),
        stoneMaterials: JSON.parse(stoneMaterials),
        filteredStoneMaterials: [],
        loading: true,
        
        loadMoreTimer: null,
        applyFilterTimer: null,
        marketingName: "",
        page: 0,    //0 before initial load
        perPage: 9,
        arHighlightIndex: 6,        //at which point in gallery should AR Gravestone appear?
        notFoundFormIndex: 12,
        pastellColors: [
            "#EEEEEE",
            "#F7F0E8",
            "#DFE8E5",
            "#F2E7E7",
            "#E3E2EB",
            "#E6EFE2",
            "#E1E8E5",
            "#E8EFE3",
            "#E3E2EA",
            "#F6F0E9",
        ],
        init: function(){
            this.page = 1;
            this.fetchGravestones();

            /*Typebot.initPopup({
                typebot: "leadgen-barnum",
                apiHost: "https://typebot.msmdt.de",
                prefilledVariables: {
                    'utm_source': Cookies.get("ms_utm_source"),
                    'utm_content': Cookies.get("ms_utm_content"),
                  },
            });
            */
        },
        initIntersectionObserver: function(){
            if(!autoLoad) return;
            let that = this;
            let observer = new IntersectionObserver(() => {
                if(!that.loading) that.loadMore();
            }, {
                //root: this.$refs.loadMore
            });
            observer.observe(this.$refs.loadMore);
        },
        applyFilterChange: function(){
            clearTimeout(this.applyFilterTimer);

            this.applyFilterTimer = setTimeout(() => {
                this.page = 1;
                this.fetchGravestones();
            }, galleryCallDebounce);
        },
        fetchGravestones: function(){
            this.loading = true;
            var url = new URL(window.location.protocol + "//" + window.location.host + "/api/gallery"),
            params = {
                materials: this.filteredStoneMaterials,
                characteristics: this.filteredStoneCharasteristics,
                types: this.filteredGravetypes,
                marketingName: this.marketingName,
                page: this.page,
                "per_page": this.perPage,
            };

            Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

            fetch(url, {
                headers: {
                    accept: "application/json",
                    contentType: "application/json"
                },
            })
            .then(response => response.json())
            .then((response) => {
                if(this.page > 1) {
                    if(this.filteredGravestones[this.filteredGravestones.length-1].type == "NotFoundForm") this.filteredGravestones.pop();  //remove last item if its the notfoundform so we can put it back to the proper position afterwards
                    this.filteredGravestones.push(...response);
                }else {
                    this.filteredGravestones = response;
                }
                this.orderGallery();
                //console.log(response, this.filteredGravestones);
            })
            .catch((response) => {
                console.log("Error", response);
            })
            .finally(() => {
                this.loading = false;
            });
        },
        /**
         *  The Gallery Grid has two special items:
         *      1. AR Highlight Bigger Box on the right side that loads a modelviewer instance
         *      //2. NotFoundForm: Big Box on the left side that show a form in which the visitor can request a catalog
         */
        orderGallery: function(){
            if(this.filteredGravestones.length >= this.arHighlightIndex+1){
                if(this.filteredGravestones[this.arHighlightIndex].isAR){
                    this.filteredGravestones[this.arHighlightIndex].type = "ARHighlight";
                }else {
                    const arIndex = this.filteredGravestones.findIndex(gravestone => gravestone.isAR);
                    if(arIndex !== undefined && arIndex >= 0){
                        let arStone = this.filteredGravestones[arIndex];
                        this.filteredGravestones[arIndex] = this.filteredGravestones[this.arHighlightIndex]
                        this.filteredGravestones[this.arHighlightIndex] = arStone;
                        this.filteredGravestones[this.arHighlightIndex].type = "ARHighlight";
                    }
                }
            }

            /*if(this.filteredGravestones.length >= this.notFoundFormIndex+1){
                if(this.filteredGravestones[this.notFoundFormIndex].type == "NotFoundForm") return; //NotfoundForm already exists
                this.filteredGravestones.splice(this.notFoundFormIndex, 0, {
                    type: "NotFoundForm"
                });
            }else {
                //Array isn't long enough so push form to the end so we have it present always
                this.filteredGravestones.push({
                    type: "NotFoundForm"
                });
            }*/
        },
        toggleTypeFilter: function(typeId){
            if(typeId === -1) return this.toggleRasengrabstein();

            var index = this.filteredGravetypes.indexOf(typeId);

            if (index === -1) {
                this.filteredGravetypes.push(typeId);
            } else {
                this.filteredGravetypes.splice(index, 1);
            }
            this.applyFilterChange();
        },
        toggleRasengrabstein: function(){
            /**
             * Kind of a dirty Workaround
             * Rasengrabstein is a "virtual" category that groups physical/backend categories
             * So make sure here that, when we select "Rasengrabstein" in Reality, the physical Categories are selected
             */
            let that = this;

            this.rasengrabsteinSelected = !this.rasengrabsteinSelected;

            rasengrabsteine.forEach(rasengrabstein => {
                var index = that.filteredGravetypes.indexOf(rasengrabstein);

                if (index === -1 && this.rasengrabsteinSelected) {
                    that.filteredGravetypes.push(rasengrabstein);
                } else if(!this.rasengrabsteinSelected) {
                    that.filteredGravetypes.splice(index, 1);
                }
            })

            this.applyFilterChange();
        },
        toggleCharasteristicsFilter: function(characteristicId){
            var index = this.filteredStoneCharasteristics.indexOf(characteristicId);

            if (index === -1) {
                this.filteredStoneCharasteristics.push(characteristicId);
            } else {
                this.filteredStoneCharasteristics.splice(index, 1);
            }
            this.applyFilterChange();
        },
        toggleMaterialsFilter: function(filterId){
            var index = this.filteredStoneMaterials.indexOf(filterId);

            if (index === -1) {
                this.filteredStoneMaterials.push(filterId);
            } else {
                this.filteredStoneMaterials.splice(index, 1);
            }
            this.applyFilterChange();
        },
        loadMore: function(){
            if(this.page == 0) return;
            clearTimeout(this.loadMoreTimer);

            this.loadMoreTimer = setTimeout(() => {
                this.page++;
                this.fetchGravestones();
            }, galleryCallDebounce);
        },
        getPastellBackground: function(id){
            if(!id) return this.pastellColors[0];
            let index = id % 10 - 1;
            return this.pastellColors[(index === -1) ? 9 : index];
        },
        openTypebot: function(){
            Typebot.open();
            if(typeof videoaskWidget !== 'undefined'){
                //videoaskWidget exists / is initialized so we need to remove it as it is abstructing the view
                videoaskWidget.remove();
                videoaskWidget = null;
            }
            setTimeout(() => {
                document.querySelector('typebot-popup').shadowRoot.querySelector('div').style.zIndex = 500;
            }, 150)
        }
    };

}


window.handleCatalogRequest = function(){

    return {
        phone: "",
        email: null,
        message: null,
        privacyApproval: false,
        loading: false,
        buttonDisabled: false,
        intlTelInput: null,
        success: false,
        sendOrderForm() {
            this.loading = true;

            fetch("/forms/requestCatalog", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    name: "",
                    phone: this.intlTelInput.getNumber(),
                    email: this.email,
                    message: this.message,
                    utmSource: Cookies.get("ms_utm_source"),
                    utmContent: Cookies.get("ms_utm_content")
                }),
            })
            .then((response) => {
                if(response.ok){
                    this.buttonDisabled = false;
                    this.buttonHidden = true;
                    this.success = true;
                }else {
                    throw new Error();  
                }
            })
            .catch(() => {
                this.$dispatch("show-error-toast");
            })
            .finally(() => {
                this.phone = this.name = this.email = this.message = null;
                this.loading = false;
            })
        },
        init() {
            if(this.intlTelInput || !this.$refs.phoneInput) return;
            this.intlTelInput = intlTelInput(this.$refs.phoneInput, {
                preferredCountries: ["de", "ch", "at"],
                initialCountry: "de",
                utilsScript: "/build/intlib_utils.js",
                customContainer: "w-100",
                customPlaceholder: function(){ return "Ihre Handynummer" }
            });
        },
        get isOrderFormValid(){
            const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
            let valid = (this.phone && this.phone.length > 0 && this.intlTelInput.isValidNumber()) && emailRegex.test(this.email);
            return valid;
        },
    }
}